// export enum Role {
//     '-uL' = "Loadder",
//     '-uA' = "Admin",
//     '-uADB' = "AdminDB",
//     '-uD' = "Downloader"
//   }



  export enum Role {
    Loadder = '-uL',
    Admin = '-uA',
    AdminDB = '-uADB',
    Downloader = '-uD',
  }