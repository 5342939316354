import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import 'rxjs/add/operator/map'



@Injectable({
  providedIn: 'root'
})
export class NetworkingServices {
  _isLoggedIn: boolean = false

  authSub = new Subject<any>();

  constructor(private _http: HttpClient, private _authService: AuthService) {

  }
  completeHeaders(headerOptions:HttpHeaders){
    headerOptions = headerOptions.append('Auth', this._authService.getJwt());
    headerOptions = headerOptions.append('Iam', sessionStorage.getItem("userAccess"));
    return headerOptions
  }

  get(Endpoint:string,  headerOptions:HttpHeaders){
    var HeaOpt = new HttpHeaders();
    HeaOpt = this.completeHeaders(headerOptions)
    return this._http.get(Endpoint, { observe: 'response' , headers: HeaOpt});    
  }
  
  post(Endpoint:string,params:any, headerOptions:HttpHeaders){
    if (!Endpoint.includes("users/login") && !Endpoint.includes("users/forgot")){
        headerOptions = this.completeHeaders(headerOptions)
    }
    return this._http.post(Endpoint, {params}, { observe: 'response', headers: headerOptions });
  }

  put(Endpoint:string,params:any,headerOptions:HttpHeaders){
    var HeaOpt = new HttpHeaders();
    HeaOpt = this.completeHeaders(headerOptions)
    return this._http.put(Endpoint, {params}, { observe: 'response', headers: HeaOpt });
  }

  /*loginAuth(userObj: any) {
    if (userObj.authcode) {
      console.log('Appending headers');
      this.headerOptions = new HttpHeaders({
        'x-tfa': userObj.authcode
      });
    }
    return this._http.post("http://localhost:3000/login", { uname: userObj.uname, upass: userObj.upass }, { observe: 'response', headers: this.headerOptions });
  }

  setupAuth() {
    return this._http.post("http://localhost:3000/tfa/setup", {}, { observe: 'response' })
  }

  registerUser(userObj: any) {
    return this._http.post("http://localhost:3000/register", { uname: userObj.uname, upass: userObj.upass }, { observe: "response" });
  }

  updateAuthStatus(value: boolean) {
    this._isLoggedIn = value
    console.log("updateAuthStatus")
    console.log(this._isLoggedIn)
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
  }

  getAuthStatus() {
    this._isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
    return this._isLoggedIn
  }

  logoutUser() {
    console.log("logout")
    console.log(this._isLoggedIn)
    this._isLoggedIn = false;
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', "false")
  }

  getAuth() {
    return this._http.get("http://localhost:3000/tfa/setup", { observe: 'response' });
  }

  deleteAuth() {
    return this._http.delete("http://localhost:3000/tfa/setup", { observe: 'response' });
  }

  verifyAuth(token: any) {
    return this._http.post("http://localhost:3000/tfa/verify", { token }, { observe: 'response' });
  }*/
}
