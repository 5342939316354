import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'app/login/login.component'

// import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { from } from 'rxjs';
import { AuthGuard } from './app-routing.guard';
import { AuthService } from './@core/services/auth.service';

import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { FirstTimeComponent } from 'app/user-profile/first-time/first-time.component';
import { ForgotPasswordComponent } from 'app/forgot-password/forgot-password.component'
import { data } from 'jquery';
const routes: Routes =[
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    children: [ {
        path: 'first-time',
        component: FirstTimeComponent
    }],
    canActivate: [AuthGuard]
  },
  {
    path: 'userzone',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },

  {
    path: 'forgot',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }























/*

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

const routes: Routes =[
  
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
*/