import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';


import { FirstTimeComponent } from 'app/user-profile/first-time/first-time.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
// import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { AuthService } from './@core/services/auth.service';
import { UserRoleDirective } from './@core/directive/user-role.directive';
import { UserDirective } from './@core/directive/user.directive';

import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { DatePipe } from '@angular/common';


import { AuthGuard } from './app-routing.guard';
import { LoginComponent } from './login/login.component';
import {MatDialogModule, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ForgotPasswordComponent } from 'app/forgot-password/forgot-password.component'
import { RedirectInterceptor } from 'app/@core/services/networkingInterceptor.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    AngularMultiSelectModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    /*AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })*/
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    // UserLayoutComponent,
    UserRoleDirective,
    UserDirective,
    LoginComponent,
    FirstTimeComponent,
    ForgotPasswordComponent
  ],
  exports:[
    UserRoleDirective,
    UserDirective
  ],
  
  providers: [AuthService,AuthGuard, DatePipe,{provide: MAT_DIALOG_DATA, useValue: {}}, {provide: MatDialogRef, useValue: {}}, { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true }],
  bootstrap: [AppComponent],

})
export class AppModule { }
